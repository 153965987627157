
import anime from 'animejs'
import { defineComponent, onMounted, ref } from 'vue'
export default defineComponent({
  setup() {
    const line = ref()
    const clip = ref()
    onMounted(() => {
      const tl = anime.timeline({ loop: true, easing: 'linear', direction: 'reverse' })
      tl.add({ targets: line.value, strokeDashoffset: [anime.setDashoffset, 0], duration: 750 }, 0)
      tl.add({ targets: clip.value, strokeDashoffset: [anime.setDashoffset, 0], duration: 1500, delay: 100 }, 0)
    })
    return { line, clip }
  }
})
