
import anime from 'animejs'
import { defineComponent } from 'vue'
export default defineComponent({
  mounted() {
    const { line1, line2, line3 } = this.$refs
    const tl = anime.timeline({ loop: true, easing: 'linear' })
    tl.add({ targets: line1, strokeDashoffset: [anime.setDashoffset, 0], duration: 500 }, 500)
    tl.add({ targets: line2, strokeDashoffset: [anime.setDashoffset, 0], duration: 250 }, '+=0')
    tl.add({ targets: line3, strokeDashoffset: [anime.setDashoffset, 0], duration: 250 }, '-=250')
    tl.add({ targets: [line1, line2, line3], opacity: 0, duration: 500 }, '+=750')
  }
})
