
import anime from 'animejs'
import { defineComponent } from 'vue'
export default defineComponent({
  mounted() {
    const { title, pulseContainer, pulse, offer1, offer2, offer3, offer4 } = this.$refs
    anime({ targets: [pulse], opacity: [0, 0.5], loop: true, easing: 'easeInOutQuad', direction: 'alternate', duration: 1000 })
    const tl = anime.timeline({ loop: true, easing: 'easeInOutQuad', duration: 300 })
    tl.add({ targets: [title, offer1, pulseContainer], opacity: [0, 1], translateY: { value: [5, 0], easing: 'easeOutQuad' } }, '+=200')
    tl.add({ targets: [title, offer1, pulseContainer], opacity: 0 }, '+=2500')
    tl.add({ targets: [title, offer2, pulseContainer], opacity: [0, 1], translateY: { value: [5, 0], easing: 'easeOutQuad' } }, '+=200')
    tl.add({ targets: [title, offer2, pulseContainer], opacity: 0 }, '+=2500')
    tl.add({ targets: [title, offer3, pulseContainer], opacity: [0, 1], translateY: { value: [5, 0], easing: 'easeOutQuad' } }, '+=200')
    tl.add({ targets: [title, offer3, pulseContainer], opacity: 0 }, '+=2500')
    tl.add({ targets: [title, offer4, pulseContainer], opacity: [0, 1], translateY: { value: [5, 0], easing: 'easeOutQuad' } }, '+=200')
    tl.add({ targets: [title, offer4, pulseContainer], opacity: 0 }, '+=2500')
  }
})
