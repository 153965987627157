
import { defineComponent, ref } from 'vue'
import Header from '@/components/Header/Header.vue'
import Browser from '@/components/Browser/Browser.vue'
import Bolt from '@/icons/Bolt/Bolt.vue'
import Quote from '@/components/Quote/Quote.vue'
import Figure1 from '@/components/Figure1/Figure1.vue'
import Sparkles from '@/icons/Sparkles/Sparkles.vue'
import Figure2 from '@/components/Figure2/Figure2.vue'
import Button from '@/components/Button/Button.vue'
import Chart from '@/icons/Chart/Chart.vue'
import Expand from '@/icons/Expand/Expand.vue'
import Figure0 from '@/components/Figure0/Figure0.vue'
import Trend from '@/icons/Trend/Trend.vue'
import Cursor from '@/icons/Cursor/Cursor.vue'
export default defineComponent({
  components: {
    Header,
    Browser,
    Bolt,
    Quote,
    Figure1,
    Sparkles,
    Figure2,
    Button,
    Chart,
    Expand,
    Figure0,
    Trend,
    Cursor
  },
  setup() {
    const videoLoop = ref()
    const videoHidden = ref()
    const fullscreen = () => {
      if (videoLoop.value.requestFullScreen) return videoLoop.value.requestFullScreen()
      else if (videoLoop.value.webkitRequestFullScreen) return videoLoop.value.webkitRequestFullScreen()
      else videoHidden.value.play()
    }
    return { videoLoop, videoHidden, fullscreen }
  }
})
