<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" class="w-4 h-4 text-gray-700">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M2.5 3.333c0-.46.373-.833.833-.833h3.334a.833.833 0 010 1.667H5.345L8.09 6.91a.833.833 0 01-1.178 1.178L4.167 5.345v1.322a.833.833 0 01-1.667 0V3.333zM13.333 2.5h3.334c.46 0 .833.373.833.833v3.334a.833.833 0 01-1.667 0V5.345L13.09 8.09a.833.833 0 01-1.178-1.178l2.744-2.744h-1.322a.833.833 0 010-1.667zM8.09 11.91a.833.833 0 010 1.18l-2.744 2.743h1.322a.833.833 0 010 1.667H3.333a.833.833 0 01-.833-.833v-3.334a.833.833 0 011.667 0v1.322L6.91 11.91a.833.833 0 011.178 0zm3.822 0a.834.834 0 011.178 0l2.744 2.745v-1.322a.833.833 0 011.667 0v3.334c0 .46-.373.833-.833.833h-3.334a.833.833 0 010-1.667h1.322L11.91 13.09a.834.834 0 010-1.178z"
      clip-rule="evenodd"
    />
  </svg>
</template>
