
import { defineComponent } from 'vue'
import Spinner from '@/components/Spinner/Spinner.vue'
import referralService from '@/services/api/services/referralService'
export default defineComponent({
  async created() {
    const affiliateCode = this.$route.params.affiliateCode as string
    const affiliateCodeWasValid = await referralService.track(affiliateCode)
    if (affiliateCodeWasValid) window.location.replace(`${process.env.VUE_APP_LISTING_URL}?affiliateCode=${affiliateCode}`)
    else this.$router.push({ name: 'Home' })
  },
  components: { Spinner }
})
