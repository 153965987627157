
import anime from 'animejs'
import { defineComponent } from 'vue'
export default defineComponent({
  mounted() {
    const { outline, listbox } = this.$refs
    const tl = anime.timeline({ loop: true, easing: 'easeInOutQuad', duration: 300 })
    tl.add({ targets: [outline, listbox], opacity: [0, 1] }, '+=200')
    tl.add({ targets: [listbox], translateY: [-5, 0], easing: 'easeOutQuad' }, '-=300')
    tl.add({ targets: [outline, listbox], opacity: 0 }, '+=2500')
  }
})
