<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="65" height="27" fill="none" viewBox="0 0 65 27">
    <path
      fill="#fff"
      fill-rule="evenodd"
      d="M7.19.921L17.576.377a7.2 7.2 0 017.567 6.813l.544 10.386a7.2 7.2 0 01-6.813 7.567l-10.386.544a7.2 7.2 0 01-7.567-6.813L.377 8.488A7.2 7.2 0 017.19.921z"
      clip-rule="evenodd"
    />
    <path
      stroke="#262626"
      stroke-linecap="round"
      stroke-width="2.4"
      d="M17.574 7.987c.151 2.88-1.895 4.906-4.542 5.045-2.647.139-4.894-1.663-5.045-4.542"
    />
    <path
      fill="#262626"
      fill-rule="evenodd"
      d="M7.19.921L17.576.377a7.2 7.2 0 017.567 6.813l.544 10.386a7.2 7.2 0 01-6.813 7.567l-10.386.544a7.2 7.2 0 01-7.567-6.813L.377 8.488A7.2 7.2 0 017.19.921zm.126 2.397a4.8 4.8 0 00-4.542 5.044l.544 10.386a4.8 4.8 0 005.044 4.542l10.386-.544a4.8 4.8 0 004.542-5.044l-.544-10.386a4.8 4.8 0 00-5.044-4.542l-10.386.544z"
      clip-rule="evenodd"
    />
    <path
      fill="#262626"
      d="M35.616 19.032h4.46c2.705 0 3.926-1.347 3.926-3.13 0-1.802-1.261-2.819-2.415-2.881v-.114c1.069-.273 1.96-1 1.96-2.455 0-1.721-1.193-3.056-3.67-3.056h-4.261v11.636zm1.755-1.506V13.8h2.597c1.41 0 2.29.91 2.29 2.017 0 .977-.665 1.71-2.352 1.71H37.37zm0-5.096V8.89h2.398c1.392 0 2.057.733 2.057 1.699 0 1.125-.92 1.84-2.102 1.84h-2.352zm11.117 6.795c1.443 0 2.256-.733 2.58-1.386h.068v1.193h1.659v-5.796c0-2.54-2-3.045-3.387-3.045-1.58 0-3.034.636-3.602 2.227l1.597.364c.25-.62.886-1.216 2.028-1.216 1.097 0 1.66.574 1.66 1.563v.04c0 .619-.637.607-2.205.79-1.654.192-3.347.624-3.347 2.607 0 1.716 1.29 2.66 2.949 2.66zm.37-1.364c-.96 0-1.654-.431-1.654-1.272 0-.91.807-1.233 1.79-1.364.55-.074 1.858-.221 2.102-.466v1.125c0 1.034-.824 1.977-2.239 1.977zm9.859 4.625c2.221 0 3.937-1.017 3.937-3.26v-8.921H60.99v1.415h-.125c-.3-.54-.903-1.529-2.528-1.529-2.108 0-3.66 1.665-3.66 4.443 0 2.784 1.586 4.267 3.649 4.267 1.602 0 2.221-.903 2.528-1.46h.108v1.716c0 1.37-.938 1.96-2.227 1.96-1.415 0-1.966-.71-2.267-1.21l-1.46.602c.46 1.068 1.624 1.977 3.71 1.977zm-.017-4.994c-1.517 0-2.307-1.165-2.307-2.88 0-1.677.773-2.978 2.307-2.978 1.483 0 2.278 1.21 2.278 2.977 0 1.802-.813 2.881-2.278 2.881z"
    />
  </svg>
</template>
