
import Toolbar from '@/icons/Toolbar/Toolbar.vue'
import { defineComponent } from 'vue'
export default defineComponent({
  components: { Toolbar },
  props: {
    aspect: {
      type: Number,
      default: 0.5238095238
    },
    url: {
      type: String,
      required: false
    }
  }
})
